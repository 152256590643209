import { useState } from "react";
import ButtonIcon from "../Controls/ButtonIcon";
import ButtonWithIconLeft from "../Controls/ButtonWithIconLeft";
import CounterSmall from "../Controls/CounterSmall";
import {
    MoreVerticalSVG,
    HeartSmallSVG,
    TrashSmallSVG,
} from "../../images/SVGAssets";
import useSuccessMessage from "../../hook/useSuccessMessage";
import { FavoriteContext } from "../../contexts/FavoriteContext";
import { BasketContext } from "../../contexts/BasketContext";
import { useContext } from "react";

function BasketListItem({ item, quantity }) {
    const [isHovered, setIsHovered] = useState(false);
    const [counter, setCounter] = useState(quantity);
    const { getSuccessMessage, trigger } = useSuccessMessage({
        type: "favorites",
    });
    const { toggleItem } = useContext(FavoriteContext);
    const { updateItem, removeItem } = useContext(BasketContext);

    const handleFavoriteClick = (e) => {
        e.stopPropagation();
        toggleItem(item);
        trigger(item);
    };

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        removeItem(item);
    };

    return (
        <>
            {item && (
                <div
                    className="w-full flex items-center px-[10px] py-[5px] border-b border-gray-lidabro-border-3 rounded-[5px] hover:bg-white"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => {
                        setIsHovered(false);
                        updateItem(item, counter);
                    }}
                >
                    <div className="flex w-[50px] sm:w-[100px] h-[50px] sm:h-[100px]">
                        {item.images.length > 0 ? (
                            <img
                                className="w-full object-scale-down"
                                src={item.images[0].url}
                                alt={item.images[0].altText}
                            />
                        ) : null}
                    </div>
                    <div className="w-full flex pl-3 py-3 gap-3 items-center">
                        <div className="flex flex-col py-3 gap-[6px] grow">
                            <div className="flex flex-col">
                                <p className="font-secondary text-xs font-medium leading-[18px] text-nowrap text-gray-lidabro-select-remove">
                                    {item.type}
                                </p>
                                <p className="font-secondary text-xs font-semibold leading-[18px] text-nowrap text-gray-lidabro-text">
                                    {item.name.length > 30
                                        ? item.name.slice(0, 30) + "..."
                                        : item.name}
                                </p>
                            </div>
                            <div className="flex gap-3">
                                {/* {item.variants.map((variant) => (
                                <p
                                    key={variant.id}
                                    className="font-secondary text-xxs font-medium leading-[18px] text-nowrap text-gray-lidabro-select-remove"
                                >
                                    {variant.label}
                                </p>
                            ))} */}
                            </div>
                        </div>
                        <div className="flex sm:hidden items-center justify-end gap-[6px]">
                            {item.hasOwnProperty("discountPrice") ? (
                                <>
                                    <p className="font-secondary text-xxs font-medium leading-4 line-through text-gray-lidabro-select-remove">
                                        ₪ {item.actualPrice.price}
                                    </p>
                                    <p className="font-secondary text-xs font-semibold leading-[18px] text-nowrap">
                                        ₪ {item.discountPrice.price}
                                    </p>
                                </>
                            ) : (
                                <p className="font-secondary text-xs font-semibold leading-[18px] text-nowrap">
                                    ₪ {item.actualPrice.price}
                                </p>
                            )}
                            <p className="font-secondary text-xs font-semibold leading-[18px] text-nowrap">
                                x{counter}
                            </p>
                            <ButtonIcon icon={<MoreVerticalSVG />} />
                        </div>
                        <div className="hidden sm:flex flex-col gap-[5px]">
                            <div className="flex items-center gap-[6px]">
                                {item.hasOwnProperty("discountPrice") ? (
                                    <>
                                        <p className="font-secondary text-[11px] font-medium leading-6 line-through text-gray-lidabro-select-remove">
                                            ₪ {item.actualPrice.price}
                                        </p>
                                        <p className="font-secondary text-lg font-semibold text-black-lidabro-review">
                                            ₪ {item.discountPrice.price}
                                        </p>
                                    </>
                                ) : (
                                    <p className="font-secondary text-lg font-semibold text-black-lidabro-review">
                                        ₪ {item.actualPrice.price}
                                    </p>
                                )}
                                {isHovered ? (
                                    <CounterSmall
                                        counter={counter}
                                        setCounter={setCounter}
                                    />
                                ) : (
                                    <p className="font-secondary text-xs font-semibold leading-[18px] text-nowrap">
                                        x{counter}
                                    </p>
                                )}
                            </div>
                            {isHovered ? (
                                <div className="flex">
                                    <ButtonWithIconLeft
                                        icon={<HeartSmallSVG />}
                                        name={"Save"}
                                        callback={handleFavoriteClick}
                                    />
                                    <ButtonWithIconLeft
                                        icon={<TrashSmallSVG />}
                                        name={"Delete"}
                                        callback={handleDeleteClick}
                                    />
                                </div>
                            ) : item.hasOwnProperty("discountPrice") ? (
                                <p className="font-secondary text-xs font-normal leading-[18px] text-gray-lidabro-border-2">
                                    {Math.round(
                                        (item.actualPrice.price -
                                            item.discountPrice.price) *
                                            counter *
                                            100
                                    ) / 100}
                                    ₪ Discount
                                </p>
                            ) : null}
                        </div>
                    </div>
                    {getSuccessMessage()}
                </div>
            )}
        </>
    );
}

export default BasketListItem;
