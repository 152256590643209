import { EyeSVG } from "../../images/SVGAssets";

function StreamSeeLiveQuestions({ eyezon, item }) {
    //TODO: Streamer Time

    return (
        <>
            <div className="flex flex-col items-center p-6 gap-[25px]">
                <p className="font-secondary text-sm font-medium text-center text-blue-lidabro-text">
                    Have specific questions? The seller will show the product.
                    No need to turn on the camera
                </p>
                <p className="font-secondary text-sm font-medium text-center text-blue-lidabro-text">
                    Get a personal live introduction to the product from the
                    seller Sa-Mo 9:00 AM - 6:00 PM!
                </p>
                <button
                    data-eyezon="232"
                    data-eyezon-title={item.name}
                    data-eyezon-target={item.ean}
                    className={`max-w-[160px] flex items-center justify-center gap-[10px] py-4 px-[22px] rounded-lg lidabro-gradient-eyezone lidabro-button-shadow text-white font-secondary text-lg font-semibold`}
                >
                    <EyeSVG fill="white" />
                    See live
                </button>
            </div>
        </>
    );
}

export default StreamSeeLiveQuestions;
