import Slider from "../Slider/Slider";
import StreamSeeLive from "../Stream/StreamSeeLive";
import ItemVariants from "../ItemVariants/ItemVariants";
import ItemVariantsDropdown from "../ItemVariants/ItemVariantsDropdown";
import ItemSeller from "../ItemSeller/ItemSeller";
import ItemDescriptionSport from "../ItemDescription/ItemDescriptionSport";
import ItemDetailsSport from "../ItemDetails/ItemDetailsSport";
import StreamSeeLiveQuestions from "../Stream/StreamSeeLiveQuestions";
import ImageCarousel from "../Carousel/ImageCarousel";
import ButtonIcon from "../Controls/ButtonIcon";
import ItemAddToBasker from "../ItemAddToBasket/ItemAddToBasker";
import { HeartSVG, ShareSVG } from "../../images/SVGAssets";
import { FavoriteContext } from "../../contexts/FavoriteContext";
import { useContext } from "react";
import useSuccessMessage from "../../hook/useSuccessMessage";

function ItemInfoSport({ item }) {
    const { getSuccessMessage, trigger } = useSuccessMessage({
        type: "favorites",
    });
    const { toggleItem } = useContext(FavoriteContext);

    const handleFavoriteClick = (e) => {
        e.stopPropagation();
        toggleItem(item);
        trigger(item);
    };
    //TODO: Stock

    return (
        <>
            <div className="flex sm:hidden flex-col px-6 py-3 gap-4">
                <div className="flex flex-col">
                    <p className="font-secondary text-sm not-italic font-normal leading-5 text-black">
                        {item.brand.name}
                    </p>
                    <p className="font-secondary text-base not-italic font-semibold leading-6">
                        {item.name}
                    </p>
                </div>
                <Slider images={item.images} />
                <div className="flex gap-2">
                    {item.hasOwnProperty("discountPrice") ? (
                        <>
                            <p className="font-main text-2xl not-italic font-semibold leading-8 text-gray-lidabro-breadcrumb">
                                ₪ {item.discountPrice.price}
                            </p>
                            <p className="font-secondary text-xl font-medium leading-[30px] text-gray-lidabro-text-2 line-through">
                                ₪ {item.actualPrice.price}
                            </p>
                        </>
                    ) : (
                        <p className="font-main text-2xl not-italic font-semibold leading-8 text-gray-lidabro-breadcrumb">
                            ₪ {item.actualPrice.price}
                        </p>
                    )}
                    <p className="font-secondary text-xl not-italic font-medium leading-[30px] text-green-lidabro-text">
                        In Stock
                    </p>
                </div>
                <StreamSeeLive eyezon={item.eyezon} item={item} />
                {item.variants.length > 0 ? (
                    <ItemVariants variants={item.variants} />
                ) : null}
                <ItemSeller seller={item.seller} />
                <ItemDescriptionSport item={item} />
                <ItemDetailsSport item={item} />
                <StreamSeeLiveQuestions eyezon={item.eyezon} item={item} />
            </div>

            <div className="hidden sm:flex md:hidden flex-col px-12 pt-6 pb-12 gap-8">
                <ImageCarousel images={item.images} />
                <div className="flex flex-col p-[25px] rounded-[20px] gap-4 bg-gray-lidabro-select-background">
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col">
                            <p className="font-secondary text-lg not-italic font-semibold leading-7 text-black-lidabro-select-text">
                                {item.brand.name}
                            </p>
                            <p className="font-secondary text-lg not-italic font-semibold leading-7 text-black-lidabro-select-text">
                                {item.name}
                            </p>
                            <p className="font-secondary text-sm not-italic font-medium leading-5 text-gray-lidabro-article">
                                #{item.id}
                            </p>
                        </div>
                        <div className="flex">
                            <ButtonIcon
                                icon={<HeartSVG />}
                                callback={handleFavoriteClick}
                            />
                            <ButtonIcon icon={<ShareSVG />} />
                        </div>
                    </div>
                    <div className="flex gap-2">
                        {item.hasOwnProperty("discountPrice") ? (
                            <>
                                <p className="font-main text-2xl not-italic font-semibold leading-8 text-gray-lidabro-text">
                                    ₪ {item.discountPrice.price}
                                </p>
                                <p className="font-main text-xl not-italic font-semibold leading-7 text-gray-lidabro-text-2 line-through">
                                    ₪ {item.actualPrice.price}
                                </p>
                            </>
                        ) : (
                            <p className="font-main text-2xl not-italic font-semibold leading-8 text-gray-lidabro-text">
                                ₪ {item.actualPrice.price}
                            </p>
                        )}
                        <p className="font-secondary text-xl not-italic font-medium leading-[30px] text-green-lidabro-text">
                            In Stock
                        </p>
                    </div>
                    <StreamSeeLive eyezon={item.eyezon} item={item} />
                    {item.variants.length > 0 ? (
                        <ItemVariants variants={item.variants} />
                    ) : null}
                    <ItemSeller seller={item.seller} />
                    <ItemDescriptionSport item={item} />
                    <ItemDetailsSport item={item} />
                    <StreamSeeLiveQuestions eyezon={item.eyezon} item={item} />
                </div>
            </div>

            <div className="hidden md:flex flex-col">
                <div className="flex flex-wrap xmd:flex-nowrap items-center justify-center gap-8 px-12 lg:px-[60px] xl:px-[150px] pt-6 pb-12 lg:pb-6">
                    <div className="w-full min-w-full flex xmd:hidden">
                        <Slider images={item.images} />
                    </div>
                    <div className="hidden xmd:flex xl:hidden grow max-w-full overflow-auto">
                        <ImageCarousel
                            images={item.images}
                            direction={"bottom"}
                        />
                    </div>
                    <div className="hidden xl:flex grow max-w-full overflow-auto">
                        <ImageCarousel
                            images={item.images}
                            direction={"left"}
                        />
                    </div>
                    <div className="max-w-[350px] lg:max-w-[480px] flex grow-0 flex-col items p-[25px] gap-4 bg-gray-lidabro-select-background rounded-[20px]">
                        <div className="flex items-center justify-between">
                            <div className="flex flex-col">
                                <p className="font-secondary text-lg not-italic font-semibold leading-7 text-black-lidabro-select-text">
                                    {item.brand.name}
                                </p>
                                <p className="font-secondary text-lg not-italic font-semibold leading-7 text-black-lidabro-select-text">
                                    {item.name}
                                </p>
                                <p className="font-secondary text-sm not-italic font-medium leading-5 text-gray-lidabro-article">
                                    #{item.id}
                                </p>
                            </div>
                            <div className="flex">
                                <ButtonIcon
                                    icon={<HeartSVG />}
                                    callback={handleFavoriteClick}
                                />
                                <ButtonIcon icon={<ShareSVG />} />
                            </div>
                        </div>
                        <div className="flex gap-2 items-center">
                            {item.hasOwnProperty("discountPrice") ? (
                                <>
                                    <p className="font-main text-2xl not-italic font-semibold leading-8 text-gray-lidabro-text">
                                        ₪ {item.discountPrice.price}
                                    </p>
                                    <p className="font-main text-xl not-italic font-semibold leading-7 text-gray-lidabro-text-2 line-through">
                                        ₪ {item.actualPrice.price}
                                    </p>
                                </>
                            ) : (
                                <p className="font-main text-2xl not-italic font-semibold leading-8 text-gray-lidabro-text">
                                    ₪ {item.actualPrice.price}
                                </p>
                            )}
                            <p className="font-secondary text-xl not-italic font-medium leading-[30px] text-green-lidabro-text">
                                In Stock
                            </p>
                        </div>
                        <StreamSeeLive eyezon={item.eyezon} item={item} />
                        {item.variants.length > 0 ? (
                            <ItemVariantsDropdown
                                key={item._id}
                                variants={item.variants}
                            />
                        ) : null}
                        <ItemAddToBasker item={item} />
                    </div>
                </div>

                <div className="flex justify-center px-12 py-10 bg-gray-lidabro-select-background">
                    <div className="xmd:w-2/3 xl:w-1/2 flex flex-col gap-6 px-10 py-12 rounded-[25px] bg-white">
                        <ItemSeller
                            seller={item.seller}
                            background={"bg-gray-lidabro-select-background"}
                        />
                        <ItemDescriptionSport item={item} collapsable={false} />
                        <ItemDetailsSport item={item} collapsable={false} />
                        <StreamSeeLiveQuestions
                            eyezon={item.eyezon}
                            item={item}
                        />
                    </div>
                </div>
            </div>
            {getSuccessMessage()}
        </>
    );
}

export default ItemInfoSport;
