import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { fetchSession } from "../functions/fetch";

// Create the authentication context
export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    // State to hold the authentication token and session ID
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [session, setSession] = useState(Cookies.get("session"));

    // Function to set the authentication token
    const signin = (newToken, callback) => {
        setToken(newToken);
        axios.defaults.headers.common["Authorization"] = "Bearer " + newToken;
        localStorage.setItem("token", newToken);
        if (callback) callback();
    };

    // Function to remove the authentication token and session
    const signout = (callback) => {
        setToken(null);
        setSession(null);
        delete axios.defaults.headers.common["Authorization"];
        localStorage.removeItem("token");
        Cookies.remove("session");
        if (callback) callback();
    };

    // useEffect to check for an existing session or fetch a new one
    useEffect(() => {
        if (!session) {
            fetchSession()
                .then((newSession) => {
                    Cookies.set("session", newSession.data.uuid, {
                        expires: new Date(newSession.data.expiryAt),
                        secure: true,
                        sameSite: "None",
                        path: "/",
                    });
                    setSession(newSession.data.uuid);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [session]);

    // Provide the authentication context to the children components
    const value = { token, session, signin, signout };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};
