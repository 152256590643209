import { BasketProvider } from "./BasketContext";
import { FavoriteProvider } from "./FavoriteContext";

const AppProviders = ({ children }) => (
    <BasketProvider>
        <FavoriteProvider>{children}</FavoriteProvider>
    </BasketProvider>
);

export default AppProviders;
