import { useState } from "react";
import { PlusSVG, MinusSVG } from "../../images/SVGAssets";
import { formatKey } from "../../functions/common";

function ItemDetailsSport({ item, collapsable }) {
	const [opened, SetOpened] = useState(collapsable ? false : true);
	const excludeKeys = [
		"name",
		"description",
		"variants",
		"images",
		"breadcrumb_path",
		"price",
		"actualPrice",
		"discountPrice",
		"updatedAt",
		"createdAt",
		"__v",
		"_id",
	];

	return (
		<>
			<div
				className={`flex items-center justify-between p-[6px] ${
					collapsable
						? "cursor-pointer border-b-[.8px] border-gray-lidabro-border-3"
						: ""
				}`}
				onClick={() => {
					if (collapsable) {
						SetOpened(!opened);
					}
				}}
			>
				<p className="font-secondary text-sm md:text-base font-medium md:font-semibold leading-5 md:leading-6 text-gray-lidabro-breadcrumb md:text-gray-lidabro-breadcrumb-bold">
					Details
				</p>
				{collapsable ? (
					<div>{opened ? <MinusSVG /> : <PlusSVG />}</div>
				) : null}
			</div>
			{opened ? (
				<div
					className={`flex flex-col  ${
						collapsable
							? "border-b-[.8px] border-gray-lidabro-border-3"
							: ""
					}`}
				>
					{Object.entries(item).map(([key, value]) =>
						!excludeKeys.includes(key) &&
						!key.endsWith("_ru") &&
						value !== null &&
						value !== "" ? (
							<div
								className="flex items-center justify-between px-[6px] py-2"
								key={key}
							>
								<p className="font-secondary text-xs md:text-base font-normal leading-[18px] md:leading-6 text-gray-lidabro-breadcrumb md:text-gray-lidabro-breadcrumb-bold">
									{formatKey(key)}
								</p>
								<p className="font-secondary text-xs md:text-base font-medium leading-[18px] md:leading-6 text-gray-lidabro-breadcrumb md:text-gray-lidabro-breadcrumb-bold">
									{typeof value === "object"
										? value.name
										: value}
								</p>
							</div>
						) : null
					)}
				</div>
			) : null}
		</>
	);
}

export default ItemDetailsSport;
