import { useState, useEffect } from "react";

function ControlWithIconSport({
    name,
    icon,
    action = () => {
        console.log(`Clicked ${name}`);
    },
    notificationsAmount = 0,
}) {
    return (
        <div
            className="relative flex flex-col items-center cursor-pointer"
            onClick={action}
        >
            {icon}
            <p className="font-secondary text-xs not-italic font-medium leading-[18px] text-white">
                {name}
            </p>
            {notificationsAmount > 0 ? (
                <div className="absolute top-[-6px] right-[-2px] flex items-center justify-center w-4 h-4 rounded-full bg-white">
                    <p className="font-secondary text-xxxs leading-7 font-bold text-black">
                        {notificationsAmount}
                    </p>
                </div>
            ) : null}
        </div>
    );
}

export default ControlWithIconSport;
