import { useState, useEffect } from "react";
import SuggestionsItemSport from "./SuggestionsItemSport";
import { fetchSuggestionsCategoriesByDepartment } from "../../functions/fetch";

function SuggestionsListSport(params) {
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchSuggestions = async () => {
        const fetchedSuggestions =
            await fetchSuggestionsCategoriesByDepartment();
        setSuggestions(fetchedSuggestions);
        setLoading(false);
    };

    useEffect(() => {
        fetchSuggestions();
    }, []);

    return (
        <div className="flex flex-1 gap-3 items-center overflow-x-auto scrollbar-none">
            {suggestions.length > 0
                ? suggestions.map((suggestion) => (
                      <SuggestionsItemSport
                          key={suggestion._id}
                          suggestion={suggestion}
                      />
                  ))
                : null}
        </div>
    );
}

export default SuggestionsListSport;
