import { useState, useEffect } from "react";
import HeaderLogo from "./HeaderLogo";
import HeaderMenu from "./HeaderMenu";
import HeaderMenuMobile from "./HeaderMenuMobile";
import HeaderLanguage from "./HeaderLanguage";

function HeaderLanding() {
    const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const minWidth = window.screen.availWidth;
            switch (minWidth) {
                case 390:
                    setIsFixed(scrollTop > 105);
                    break;

                default:
                    setIsFixed(scrollTop > 150);
                    break;
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <header
            className={`bg-header ${isFixed ? "fixed left-0 top-0 z-50" : ""}`}
        >
            <div className="flex items-center shrink justify-between py-6 lg:py-12 px-11 lg:px-14 xl:px-40">
                <HeaderLogo />
                <HeaderMenu />
                <HeaderLanguage />
                <HeaderMenuMobile />
            </div>
        </header>
    );
}

export default HeaderLanding;
