import { useState, useEffect } from "react";
import { HeartSVG, BasketSVG2 } from "../../images/SVGAssets";
import useSuccessMessage from "../../hook/useSuccessMessage";
import { FavoriteContext } from "../../contexts/FavoriteContext";
import { BasketContext } from "../../contexts/BasketContext";
import { useContext } from "react";

function FloatingIsland({ navMenuHeight, item }) {
    const [isScrolledPast, setIsScrolledPast] = useState(false);
    const { getSuccessMessage, trigger } = useSuccessMessage({
        type: "favorites",
    });
    const {
        getSuccessMessage: getSuccessMessageBasket,
        trigger: triggerBasket,
    } = useSuccessMessage({
        type: "basket",
    });
    const { addItem } = useContext(BasketContext);
    const { toggleItem } = useContext(FavoriteContext);
    const handleFavoriteClick = (e) => {
        e.stopPropagation();
        toggleItem(item);
        trigger(item);
    };
    const handleAddClick = (e) => {
        addItem(item);
        triggerBasket(item);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > navMenuHeight) {
                setIsScrolledPast(true);
            } else {
                setIsScrolledPast(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [navMenuHeight]);

    return (
        <>
            {isScrolledPast && (
                <div className="w-full fixed top-0 flex items-center justify-between px-6 py-4 md:hidden z-50 bg-white">
                    <div
                        className="cursor-pointer"
                        onClick={handleFavoriteClick}
                    >
                        <HeartSVG />
                    </div>
                    <button
                        className="flex items-center justify-center px-[22px] py-4 gap-[10px] rounded-lg bg-blue-lidabro-button shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
                        onClick={() => {
                            handleAddClick(item, 1);
                        }}
                    >
                        <BasketSVG2 />
                        <p className="font-secondary text-lg font-semibold text-white text-opacity-90">
                            Add to basket
                        </p>
                    </button>
                    {getSuccessMessage()}
                    {getSuccessMessageBasket()}
                </div>
            )}
        </>
    );
}

export default FloatingIsland;
