import { useContext } from "react";
import { LanguageContext } from "../HOC/Language";
import { useTranslation, Trans } from "react-i18next";
import Icon from "../Shared/Icon";
import {
    LiveIcon,
    POV360Icon,
    ArrowRightIcon,
    ArrowLeftIcon,
} from "../Shared/SVGComponents";

function ForShoppersSignUp(props) {
    const {
        language,
        rowDirection,
        colDirection,
        textDirection,
        itemsDirection,
    } = useContext(LanguageContext);
    const { t } = useTranslation();

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div
            className={`flex flex-col ${rowDirection(
                "lg"
            )} gap-9 p-12 rounded-3xl bg-white font-main text-lg lg:text-xl font-normal text-gray-lidabro-dark`}
        >
            <div
                className={`flex flex-col gap-11 lg:gap-9 ${textDirection()} ${itemsDirection()}`}
            >
                <h4 className="text-2xl lg:text-5xl text-black-lidabro">
                    <Trans
                        i18nKey={"Efficiency of online shopping"}
                        components={{
                            gradient: (
                                <span className="lidabro-text4 lg:lidabro-text" />
                            ),
                        }}
                    />
                </h4>
                <p>{t("Lidabro is not just another marketplace")}</p>
                <div
                    className={`hidden w-fit lg:flex ${rowDirection()} items-center justify-center px-5 py-3 gap-4 rounded-3xl font-main text-xl font-medium bg-violet-lidabro-light text-blue-lidabro-landing-dark cursor-pointer`}
                    onClick={() => scrollToSection("ForShoppersSubscribe")}
                >
                    <p>{t("Sign Up")}</p>
                    {language === "iw" ? <ArrowLeftIcon /> : <ArrowRightIcon />}
                </div>
            </div>
            <div>
                <hr className="h-full border-r border-gray-lidabro-landing-divider" />
            </div>
            <div
                className={`flex flex-col items-center ${itemsDirection(
                    "lg"
                )} justify-between gap-11 lg:gap-0`}
            >
                <div className="flex gap-3">
                    <Icon image={<LiveIcon />} />
                    <Icon image={<POV360Icon />} />
                </div>
                <div>
                    <p>{t("With Lidabro")}</p>
                </div>
                <div
                    className={`flex ${rowDirection()} w-fit lg:hidden items-center justify-center px-5 py-3 gap-4 rounded-3xl font-main text-xl font-medium bg-violet-lidabro-light text-blue-lidabro-landing-dark cursor-pointer`}
                    onClick={() => scrollToSection("ForShoppersSubscribe")}
                >
                    <p>{t("Sign Up")}</p>
                    {language === "iw" ? <ArrowLeftIcon /> : <ArrowRightIcon />}
                </div>
            </div>
        </div>
    );
}

export default ForShoppersSignUp;
