import { useEffect } from "react";
import Landing from "../pages/Main/Landing";
import HeaderLanding from "../landing/Components/Header/Header";
import FooterLanding from "../landing/Components/Footer/Footer";
import { Context } from "../landing/Components/HOC/Language";

function LandingContainer() {
	useEffect(() => {
		(function (m, e, t, r, i, k, a) {
			m[i] =
				m[i] ||
				function () {
					(m[i].a = m[i].a || []).push(arguments);
				};
			m[i].l = 1 * new Date();
			for (var j = 0; j < document.scripts.length; j++) {
				if (document.scripts[j].src === r) {
					return;
				}
			}
			(k = e.createElement(t)),
				(a = e.getElementsByTagName(t)[0]),
				(k.async = 1),
				(k.src = r),
				a.parentNode.insertBefore(k, a);
		})(
			window,
			document,
			"script",
			"https://mc.yandex.ru/metrika/tag.js",
			"ym"
		);

		ym(98895916, "init", {
			clickmap: true,
			trackLinks: true,
			accurateTrackBounce: true,
			webvisor: true,
			ecommerce: "dataLayer",
		});
	}, []);

	return (
		<Context>
			<div className="bg-page">
				<div className="flex flex-col items-center -z-50">
					<HeaderLanding />
					<div className="z-10 relative">
						<Landing />
					</div>
					<FooterLanding />
					<div>
						<img
							src="https://mc.yandex.ru/watch/98895916"
							style={{ position: "absolute", left: "-9999px" }}
							alt=""
						/>
					</div>
				</div>
			</div>
		</Context>
	);
}

export default LandingContainer;
