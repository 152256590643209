import { useState, useEffect, useContext } from "react";
import { LanguageContext } from "../HOC/Language";
import { useTranslation } from "react-i18next";
import { submitEmail } from "../../../functions/fetch";
import { isEmail, isEmpty } from "validator";
import Icon from "../Shared/Icon";
import { GiftIcon, PercentIcon, EditIcon } from "../Shared/SVGComponents";

function ForShoppersSubscribe(props) {
    const defaultFormData = {
        email: "",
        ip: "",
    };

    const validationStatusDefault = {
        email: false,
    };

    const [formData, setFormData] = useState(defaultFormData);
    const [formState, setFormState] = useState("default");
    const [validationStatus, setValidationStatus] = useState(
        validationStatusDefault
    );
    const {
        language,
        rowDirection,
        colDirection,
        textDirection,
        itemsDirection,
    } = useContext(LanguageContext);
    const { t } = useTranslation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValidationStatus(validationStatusDefault);
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleBack = (e) => {
        setFormState("default");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isEmpty(formData.email)) {
            return;
        }

        if (!isEmail(formData.email)) {
            console.log("Not email");
            setValidationStatus((prevState) => ({
                ...prevState,
                email: true,
            }));
        }

        if (isEmail(formData.email)) {
            let successful = submitEmail(formData);
            if (successful == "true") {
                setFormData(defaultFormData);
                setFormState("submitted");
            } else {
                setFormState("error");
            }
        }
    };

    useEffect(() => {
        return () => {};
    }, [formState]);

    const renderForm = (formState) => {
        switch (formState) {
            case "default":
                return (
                    <>
                        <div
                            className={`flex flex-col ${itemsDirection()} ${textDirection()} gap-6 flex-[1_0_0%] font-main text-xl font-medium text-white`}
                        >
                            <div className="flex gap-3">
                                <Icon
                                    image={<GiftIcon />}
                                    bgColor="bg-white-lidabro bg-opacity-25"
                                />
                                <Icon
                                    image={<PercentIcon />}
                                    bgColor="bg-white-lidabro bg-opacity-25"
                                />
                            </div>
                            <p>{t("Stay tuned")}</p>
                        </div>
                        <div>
                            <hr className="h-full border-r border-gray-white border-opacity-20 rounded-sm" />
                        </div>
                        <div className="flex flex-col justify-center flex-[1_0_0%]">
                            <form
                                className={`w-full flex flex-col ${rowDirection(
                                    "lg"
                                )} items-center justify-center gap-2`}
                                onSubmit={handleSubmit}
                            >
                                <input
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    type="text"
                                    className={`w-full px-5 py-2.5 rounded-3xl outline-none bg-opacity-20 bg-white border border-white border-opacity-50 text-white ${textDirection()} placeholder:text-white placeholder:font-main placeholder:text-sm placeholder:font-medium`}
                                    placeholder={t("Your email address...")}
                                />
                                <button
                                    type="submit"
                                    className="w-full lg:w-fit px-5 py-3 bg-white rounded-3xl font-main text-sm font-medium text-blue-lidabro-landing-dark text-nowrap"
                                >
                                    {t("Sign Up")}
                                </button>
                            </form>
                            {validationStatus["email"] && (
                                <p className="px-3 py-1 text-sm text-white">
                                    Email is invalid
                                </p>
                            )}
                        </div>
                    </>
                );
            case "submitted":
                return (
                    <>
                        <div className="flex flex-col items-center gap-6 flex-[1_0_0%] font-main text-xl font-medium text-white text-center">
                            <div className="flex gap-3">
                                <Icon
                                    image={<GiftIcon />}
                                    bgColor="bg-white-lidabro bg-opacity-25"
                                />
                                <Icon
                                    image={<PercentIcon />}
                                    bgColor="bg-white-lidabro bg-opacity-25"
                                />
                            </div>
                            <p>{t("Subscription Successful Header")}</p>
                            <p>{t("Subscription Successful Body")}</p>
                            <button
                                type="button"
                                className="w-full lg:w-fit px-5 py-3 bg-white rounded-3xl font-main text-sm font-medium text-blue-lidabro-landing-dark text-nowrap"
                                onClick={handleBack}
                            >
                                {t("Return to Form")}
                            </button>
                        </div>
                    </>
                );
            case "error":
                return (
                    <>
                        <div className="flex flex-col items-center gap-6 flex-[1_0_0%] font-main text-xl font-medium text-white text-center">
                            <div className="flex gap-3">
                                <Icon
                                    image={<EditIcon />}
                                    bgColor="bg-white-lidabro bg-opacity-25"
                                />
                            </div>
                            <p>{t("Subscription Error Header")}</p>
                            <p>{t("Subscription Error Body")}</p>
                            <button
                                type="button"
                                className="w-full lg:w-fit px-5 py-3 bg-white rounded-3xl font-main text-sm font-medium text-blue-lidabro-landing-dark text-nowrap"
                                onClick={handleBack}
                            >
                                {t("Return to Form")}
                            </button>
                        </div>
                    </>
                );
        }
    };

    return (
        <div
            id="ForShoppersSubscribe"
            className={`flex flex-col ${rowDirection(
                "lg"
            )} gap-9 px-12 py-14 rounded-3xl bg-blue-lidabro-landing-default`}
        >
            {renderForm(formState)}
        </div>
    );
}

export default ForShoppersSubscribe;
