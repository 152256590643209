import ItemsFilterPrice from "./ItemsFilterPrice";
import ItemsFilter from "./ItemsFilter";
import { fetchFiltersByCategory } from "../../functions/fetch";

function ItemsFilters({ items, setFilteredItems, category }) {
    const filters = fetchFiltersByCategory(category);

    return (
        <div className="w-full flex flex-col px-3 border-t border-r border-gray-lidabro-border-3">
            <ItemsFilterPrice
                items={items}
                setFilteredItems={setFilteredItems}
            />
            {filters.map((filter) => (
                <ItemsFilter
                    key={filter.name}
                    name={filter.name}
                    children={filters}
                />
            ))}
        </div>
    );
}

export default ItemsFilters;
