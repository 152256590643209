import { useState } from "react";
import FavoriteListItem from "./FavoriteListItem";

function FavoriteListItems({ items }) {
    return (
        <>
            <div className="flex flex-col">
                {items.map((item, index) => (
                    <FavoriteListItem key={index} item={item.item} />
                ))}
            </div>
        </>
    );
}

export default FavoriteListItems;
