import { forwardRef } from "react";
import Breadcrumb from "./Breadcrumb";
import { ChevronLeftSVG } from "../../images/SVGAssets";

const Breadcrumbs = forwardRef((params, ref) => {
    console.log(params);
    let currentCategory;
    if (params.path.length > 0) {
        currentCategory = params.path[params.path.length - 1];
    }

    return (
        <div ref={ref}>
            {!params.path.length > 0 ? (
                <>
                    <div className="flex sm:hidden items-center gap-[16px] px-6 xmd:px-[60px] lg:px-[160px] py-3 md:py-6">
                        <div>
                            <ChevronLeftSVG />
                        </div>
                        <Breadcrumb path={"Home"} last={true} />
                    </div>
                    <div className="hidden sm:flex p-6 gap-[16px]">
                        <p className="font-main text-2xl not-italic font-medium leading-8 text-black-lidabro text-nowrap">
                            Home
                        </p>
                        <div className="flex items-center gap-2 font-secondary text-sm font-medium text-gray-lidabro-breadcrumb">
                            <Breadcrumb key={0} path={"Home"} last={true} />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex sm:hidden items-center gap-[16px] px-6 xmd:px-[60px] lg:px-[160px] py-3 md:py-6">
                        <div>
                            <ChevronLeftSVG />
                        </div>
                        {params.path.length > 0 ? (
                            <Breadcrumb
                                path={params.path[params.path.length - 1]}
                                last={true}
                            />
                        ) : (
                            <Breadcrumb path={"Home"} last={true} />
                        )}
                    </div>

                    <div className="hidden sm:flex p-6 gap-[16px]">
                        <p className="font-main text-2xl not-italic font-medium leading-8 text-black-lidabro text-nowrap">
                            {currentCategory.name}
                        </p>
                        <div className="flex items-center gap-2 font-secondary text-sm font-medium text-gray-lidabro-breadcrumb">
                            {params.path.length > 0 ? (
                                params.path.map((element, index) => (
                                    <Breadcrumb
                                        key={index}
                                        path={element}
                                        last={params.path.length - 1 === index}
                                    />
                                ))
                            ) : (
                                <Breadcrumb key={0} path={"Home"} last={true} />
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
});

export default Breadcrumbs;
