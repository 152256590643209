import ItemsListItem from "./ItemsListItem";
import ItemCardSport from "../ItemCard/ItemCardSport";

function ItemsList(params) {
    return (
        <>
            <div className="flex md:hidden flex-wrap justify-center gap-3 px-3 pb-3">
                {params.items.map((item) => (
                    <ItemsListItem key={item._id} item={item} />
                ))}
            </div>

            <div className="hidden md:flex flex-wrap gap-3 px-3 pb-3">
                {params.items.map((item) => (
                    <ItemCardSport key={item._id} item={item} />
                ))}
            </div>
        </>
    );
}

export default ItemsList;
