function ButtonIcon({ icon, callback, text }) {
    return (
        <div
            className="w-full gap-[10px] flex py-2 px-3 items-center justify-center cursor-pointer"
            onClick={callback}
        >
            {icon}
            {text && (
                <p className="font-secondary text-lg font-semibold text-white text-opacity-90">
                    {text}
                </p>
            )}
        </div>
    );
}

export default ButtonIcon;
