import { useTranslation, Trans } from "react-i18next";
import Icon from "../Shared/Icon";
import {
    LiveIcon,
    SupportIcon,
    MousePointerIcon,
    UserCheckIcon,
} from "../Shared/SVGComponents";

function ForShoppersAdvantages(props) {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col gap-[73px]">
            <div className="px-9 font-main text-2xl lg:text-5xl font-normal text-center lg:px-11">
                <h4>
                    <Trans
                        i18nKey={"Shop live with Lidabro"}
                        components={{
                            gradient: <span className="lidabro-text2" />,
                        }}
                    />
                </h4>
            </div>
            <div className="flex flex-col lg:flex-row gap-12 lg:gap-6 p-12 rounded-3xl bg-white font-main text-xl font-normal text-gray-lidabro-dark">
                <div className="flex flex-[1_0_0%] flex-col gap-7 items-center text-center">
                    <Icon image={<LiveIcon />} />
                    <p>{t("Get live")}</p>
                </div>
                <div className="hidden lg:block">
                    <hr className="h-full border-r border-gray-lidabro-landing-divider" />
                </div>
                <div className="flex flex-[1_0_0%] flex-col gap-7 items-center text-center">
                    <Icon image={<SupportIcon />} />
                    <p>{t("Ask questions")}</p>
                </div>
                <div className="hidden lg:block">
                    <hr className="h-full border-r border-gray-lidabro-landing-divider" />
                </div>
                <div className="flex flex-[1_0_0%] flex-col gap-7 items-center text-center">
                    <Icon image={<MousePointerIcon />} />
                    <p>{t("Connect directly")}</p>
                </div>
                <div className="hidden lg:block">
                    <hr className="h-full border-r border-gray-lidabro-landing-divider" />
                </div>
                <div className="flex flex-[1_0_0%] flex-col gap-7 items-center text-center">
                    <Icon image={<UserCheckIcon />} />
                    <p>{t("Receive personalized consultation")}</p>
                </div>
            </div>
        </div>
    );
}

export default ForShoppersAdvantages;
