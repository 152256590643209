import About from "../../landing/Components/About/About";
import ForShoppers from "../../landing/Components/ForShoppers/ForShoppers";
import ForSellers from "../../landing/Components/ForSellers/ForSellers";
import ContactUs from "../../landing/Components/ContactUs/ContactUs";

function Landing() {
    return (
        <>
            <About />
            <ForShoppers />
            <ForSellers />
            <ContactUs />
        </>
    );
}

export default Landing;
