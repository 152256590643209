import SingleChoice from "../SellerCatalog/SingleChoice";
import { useNavigate } from "react-router-dom";

function ItemVariantsDropdown(params) {
    const navigate = useNavigate();

    return (
        <>
            <SingleChoice
                values={params.variants}
                onChange={(newValue) => {
                    navigate(`/sport/items/${newValue.article}`);
                }}
            />
        </>
    );
}

export default ItemVariantsDropdown;
