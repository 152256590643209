function InputFieldDashed({ name, id, placeholder, value, onChange }) {
    return (
        <input
            className="w-full px-3 py-[10px] border border-dashed border-gray-lidabro-border-2 rounded-lg placeholder:font-secondary placeholder:text-sm placeholder:font-medium placeholder:text-gray-lidabro-text-2 bg-inherit"
            type="text"
            name={name}
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        />
    );
}

export default InputFieldDashed;
