import { useContext } from "react";
import { LanguageContext } from "../HOC/Language";
import { useTranslation, Trans } from "react-i18next";
import { ArrowRightIcon, ArrowLeftIcon } from "../Shared/SVGComponents";

function ForSellersAbout(props) {
    const {
        language,
        rowDirection,
        colDirection,
        textDirection,
        itemsDirection,
    } = useContext(LanguageContext);
    const { t } = useTranslation();

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div
            className={`flex flex-col ${rowDirection(
                "lg"
            )} gap-9 lidabro-border bg-mobile-analytic lg:bg-none lg:border-0 lg:py-0 lg:px-11`}
        >
            <div
                className={`py-12 px-5 flex flex-col items-center ${itemsDirection(
                    "lg"
                )} ${textDirection()} gap-9 font-main text-xl font-normal text-gray-lidabro-dark flex-[1_0_0%]`}
            >
                <h4 className="text-2xl lg:text-5xl text-black-lidabro">
                    <Trans
                        i18nKey={"Sell live"}
                        components={{
                            gradient: <span className="lidabro-text3" />,
                        }}
                    />
                </h4>
                <p>{t("Lidabro uniquely empowers sales")}</p>
                <div
                    className={`w-fit flex ${rowDirection()} items-center justify-center px-5 py-3 gap-4 rounded-3xl font-main text-xl font-medium bg-blue-lidabro-landing-default text-white cursor-pointer`}
                    onClick={() => scrollToSection("ContactUs")}
                >
                    <p>{t("Contact Us")}</p>
                    {language === "iw" ? <ArrowLeftIcon /> : <ArrowRightIcon />}
                </div>
            </div>
            <div className="hidden lg:flex flex-[1_0_0%] lg:max-h-[510px]">
                <img className="object-contain" src="analytics2.png" alt="" />
            </div>
            <div className="flex justify-center rounded-3xl bg-mobile-analytic2 lg:hidden flex-[1_0_0%]">
                <img
                    className="-z-10 rounded-3xl"
                    src="analytics_mobile.png"
                    alt=""
                />
            </div>
        </div>
    );
}

export default ForSellersAbout;
