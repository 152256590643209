import FooterLogo from "./FooterLogo";

function FooterSport(params) {
    return (
        <div className="flex flex-col items-center justify-center px-[160px] py-10 gap-3 bg-gray-lidabro-text">
            <FooterLogo />
            <div className="flex flex-row py-[10px] gap-[20px] font-secondary text-sm not-italic font-medium leading-5 text-white text-center text-nowrap">
                <a
                    href="/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer"
                >
                    What is Lidabro?
                </a>
                <p>Contact Us</p>
            </div>
        </div>
    );
}

export default FooterSport;
