import { useEffect } from "react";
import { AuthProvider } from "./hoc/AuthProvider";
import AppProviders from "./contexts/AppProviders";
import Routes from "./hoc";
import "./styles/App.css";

function App() {
    return (
        <AuthProvider>
            <AppProviders>
                <Routes />
            </AppProviders>
        </AuthProvider>
    );
}

export default App;
