import ItemVariant from "./ItemVariant";

function ItemVariants({ variants }) {
    return (
        <>
            <div className="flex gap-[10px] overflow-x-auto scrollbar-none">
                {variants.map((variant) => (
                    <ItemVariant key={variant.id} variant={variant} />
                ))}
            </div>
        </>
    );
}

export default ItemVariants;
