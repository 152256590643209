import { useNavigate } from "react-router-dom";

function ItemVariant({ variant }) {
	const navigate = useNavigate();

	return (
		<>
			<button
				className="flex items-center justify-center px-6 py-[10px] border border-gray-lidabro-border-3 rounded-lg font-secondary text-base font-normal leading-6 text-gray-lidabro-placeholder bg-white"
				onClick={() => {
					navigate(`/sport/items/${variant.article}`);
				}}
			>
				{variant.value}
			</button>
		</>
	);
}

export default ItemVariant;
