import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import { fetchSearchAutocomplete } from "../../functions/fetch";
import { SearchSVG } from "../../images/SVGAssets";

function SearchBarSport({ placeholderText }) {
    const [query, setQuery] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const navigate = useNavigate();

    const fetchSuggestions = useCallback(
        debounce(async (query) => {
            if (query.length >= 3) {
                const results = await fetchSearchAutocomplete(query);
                setSuggestions(results);
            } else {
                setSuggestions([]);
            }
        }, 300),
        []
    );

    useEffect(() => {
        fetchSuggestions(query);
    }, [query, fetchSuggestions]);

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();
            if (query) {
                navigate(`/sport/search?query=${query}`);
            }
        },
        [query, navigate]
    );

    return (
        <form className="w-full flex items-center h-11" onSubmit={handleSubmit}>
            <div className="w-full flex items-center justify-center bg-black-lidabro-light rounded-lg border border-gray-lidabro-border-5 px-5">
                <div onClick={handleSubmit}>
                    <SearchSVG />
                </div>
                <input
                    className="w-full h-11 p-2 bg-black-lidabro-light focus:outline-none font-secondary text-sm font-medium text-gray-lidabro-placeholder-2"
                    type="search"
                    id="search"
                    placeholder={placeholderText}
                    autoComplete="off"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    list="autocomplete-results"
                />
                <datalist id="autocomplete-results">
                    {suggestions.map((suggestion, index) => (
                        <option value={suggestion} key={index} />
                    ))}
                </datalist>
            </div>
        </form>
    );
}

export default SearchBarSport;
