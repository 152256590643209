import { useState, useEffect } from "react";
import Counter from "../Controls/Counter";
import ButtonIcon from "../Controls/ButtonIcon";
import { ShoppingBag2SVG } from "../../images/SVGAssets";
import { BasketContext } from "../../contexts/BasketContext";
import { useContext } from "react";

function ItemAddToBasker({ item }) {
    const [quantity, setQuantity] = useState(1);
    const [clicked, setClicked] = useState(false);
    const { addItem, updateItem, basketItems } = useContext(BasketContext);

    const handleClick = () => {
        const existingBasketItem = basketItems.find(
            (basketItem) => basketItem.item.article === item.article
        );

        if (existingBasketItem) {
            updateItem(item, quantity + existingBasketItem.quantity);
        } else {
            addItem(item, quantity);
        }
        setClicked(true);
    };

    useEffect(() => {
        setQuantity(1);
        setClicked(false);
    }, [item]);

    return (
        <div className="flex gap-4">
            <Counter counter={quantity} setCounter={setQuantity} />
            <button
                className={`flex xl:hidden grow rounded-lg  hover:bg-blue-lidabro-button ${
                    clicked
                        ? "bg-blue-lidabro-button-pressed"
                        : "bg-gray-lidabro-breadcrumb"
                }`}
            >
                <ButtonIcon
                    icon={<ShoppingBag2SVG />}
                    callback={() => {
                        handleClick();
                    }}
                />
            </button>
            <button
                className={`hidden xl:flex grow rounded-lg  hover:bg-blue-lidabro-button ${
                    clicked
                        ? "bg-blue-lidabro-button-pressed"
                        : "bg-gray-lidabro-breadcrumb"
                }`}
            >
                <ButtonIcon
                    icon={<ShoppingBag2SVG />}
                    text={clicked ? "Add again" : "Add to basket"}
                    callback={() => {
                        handleClick();
                    }}
                />
            </button>
        </div>
    );
}

export default ItemAddToBasker;
