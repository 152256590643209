import React from "react";

function ButtonWithIconLeft({ name, icon, callback }) {
    return (
        <div
            className="flex flex-row items-center cursor-pointer p-[5px] gap-[3px]"
            onClick={callback}
        >
            {icon}
            <p className="font-secondary text-xs font-normal leading-[18px] text-gray-lidabro-breadcrumb text-nowrap">
                {name}
            </p>
        </div>
    );
}

export default ButtonWithIconLeft;
