import { useState } from "react";

function Slider(params) {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className="relative w-full flex flex-col justify-center items-end self-stretch gap-[5px] p-3">
            <div className="flex grow w-full max-h-[50vh]">
                {params.images.length > 0 ? (
                    <img
                        className="min-h-[250px] w-full object-scale-down"
                        src={params.images[currentIndex].url}
                        alt={params.images[currentIndex].altText}
                    />
                ) : (
                    <img
                        className="min-h-[250px] w-full object-scale-down"
                        src={
                            "https://via.placeholder.com/1000x1000?text=No+image"
                        }
                        alt={"No image"}
                    />
                )}
            </div>
            <div className="flex gap-3 absolute left-[22px] top-[22px]">
                {params.images.length > 1
                    ? params.images.map((image, index) => (
                          <span
                              key={index}
                              className={`inline-block w-2 h-2 rounded-full ${
                                  index === currentIndex
                                      ? "bg-blue-lidabro-button"
                                      : "bg-gray-lidabro-border-3"
                              }`}
                              onClick={() => goToSlide(index)}
                          />
                      ))
                    : null}
            </div>
        </div>
    );
}

export default Slider;
